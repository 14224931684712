import type {
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query";
import { fetchBaseQuery } from "@reduxjs/toolkit/query";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_MEDUSA_BASE_URL as string,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const adminUser = (getState() as any).user.admin;
    if (adminUser) {
      headers.set(
        "x-medusa-access-token",
        localStorage.getItem("api_token") as string,
      );
    }
    const customer = (getState() as any).user.customer;
    if (customer) {
      headers.set(
        "x-medusa-access-token",
        localStorage.getItem("customer_api_token") as string,
      );
    }
    return headers;
  },
  credentials: "include", // This allows server to set cookies
});
export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  return baseQuery(args, api, extraOptions);
};

export const reportingBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_REPORTING_SERVICE_BASE_URL as string,
  credentials: "include", // This allows server to set cookies
});

export const adsBaseQuery = fetchBaseQuery({
  baseUrl: process.env.NEXT_PUBLIC_ADS_SERVICE_BASE_URL as string,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const adminUser = (getState() as any).user.admin;
    if (adminUser) {
      headers.set(
        "Authorization",
        `Bearer ${localStorage.getItem("api_token")}`,
      );
    }
    return headers;
  },
  credentials: "include",
});
